import { Link } from "gatsby"
import React from "react"
import Navigation from "./Navigation/Navigation.js"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollDown: false,
    }
  }
  render() {
    return (
      <div>
        <div className="header-height-adjuster"></div>
        <header
          className={
            "layout--tablet-width " +
            (this.state.scrollDown ? "header-fixed" : "")
          }
        >
          <Link className="header__title" to="/">
            <h1>Jongskogen borettslag</h1>
          </Link>
          <Navigation menuItems={this.props.menuItems} />
        </header>
      </div>
    )
  }
  scrollEvent(previousScrollValue) {
    let currentScrollValue = window.pageYOffset

    if (currentScrollValue > 0 && currentScrollValue > previousScrollValue) {
      previousScrollValue = currentScrollValue
      if (currentScrollValue >= 80) {
        this.setState({ scrollDown: true })
      }
    } else {
      previousScrollValue = currentScrollValue
      this.setState({ scrollDown: false })
    }
  }
  componentDidMount() {
    let previousScrollValue = 0
    window.addEventListener("scroll", this.scrollEvent(previousScrollValue))
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollEvent(0))
  }
}

export default Header
