import { Link } from "gatsby"
import React from "react"

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
    this.toggleBurgerMenu = this.toggleBurgerMenu.bind(this)
  }

  toggleBurgerMenu(e) {
    this.setState({ menuOpen: !this.state.menuOpen })
    e.stopPropagation()
  }

  renderMenuItems() {
    let menuItems = this.props.menuItems
    return menuItems.map(item => {
      return (
        <li key={item.page.id}>
          <Link to={`/${item.page.pageUrl.current}`}>{item.title}</Link>
        </li>
      )
    })
  }
  render() {
    let menuItems = this.renderMenuItems()
    let burgerMenu = (
      <div
        id="burgerMenuNav"
        className={
          "burger-menu-nav " +
          (this.state.menuOpen ? "burger-menu-nav--open" : "")
        }
      >
        <ul>
          <li>
            <Link to="/">Forsiden</Link>
          </li>
          {menuItems}
        </ul>
      </div>
    )
    return (
      <nav className="header-nav">
        <ul className="header-nav__menu">{menuItems}</ul>
        <div
          className={
            "burger-icon " + (this.state.menuOpen ? "burger-icon--open" : "")
          }
          onClick={e => this.toggleBurgerMenu(e)}
        >
          <div className="burger-icon__slice" />
        </div>
        {burgerMenu}
      </nav>
    )
  }
}

export default Navigation
