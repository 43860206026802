import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons"

const Footer = props => {
  let socialMediaItems = props.socialMedia
  socialMediaItems = socialMediaItems.map(media => {
    return media.title.toLowerCase() === "facebook" ? (
      <li key={media.title}>
        <a href={media.link}>
          <FontAwesomeIcon
            className="social-media__icon"
            icon={faFacebookSquare}
            size="1x"
          />{" "}
          {media.title}
        </a>
      </li>
    ) : (
      <li key={media.title}>
        <a href={media.link}>{media.title}</a>
      </li>
    )
  })
  let socialMedia = (
    <div className="social-media">
      <strong>Sosiale medier</strong>
      <ul>{socialMediaItems}</ul>
    </div>
  )
  let contact = props.contact[0]
  let phone = <li>Tlf: {contact.phone}</li>
  contact = (
    <div className="contact">
      <strong>Kontaktinfo til styret</strong>
      <ul>
        <li key={contact.email}>
          <span className="title">E-mail:</span>
          <a href={"mailto:" + contact.email}>{contact.email}</a>
        </li>
        {contact.phone ? phone : null}
      </ul>
    </div>
  )

  return (
    <footer>
      <div className="contact-wrapper">
        {contact}
        {socialMedia}
      </div>
      <div>© {new Date().getFullYear()}, Jongskogen borettslag</div>
    </footer>
  )
}

export default Footer
